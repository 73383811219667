// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-can-you-download-soundcloud-on-pc-tsx": () => import("./../../../src/pages/can-you-download-soundcloud-on-pc.tsx" /* webpackChunkName: "component---src-pages-can-you-download-soundcloud-on-pc-tsx" */),
  "component---src-pages-can-you-download-soundcloud-songs-on-iphone-tsx": () => import("./../../../src/pages/can-you-download-soundcloud-songs-on-iphone.tsx" /* webpackChunkName: "component---src-pages-can-you-download-soundcloud-songs-on-iphone-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-download-soundcloud-for-mac-tsx": () => import("./../../../src/pages/download-soundcloud-for-mac.tsx" /* webpackChunkName: "component---src-pages-download-soundcloud-for-mac-tsx" */),
  "component---src-pages-how-to-download-soundcloud-likes-tsx": () => import("./../../../src/pages/how-to-download-soundcloud-likes.tsx" /* webpackChunkName: "component---src-pages-how-to-download-soundcloud-likes-tsx" */),
  "component---src-pages-how-to-download-soundcloud-playlist-tsx": () => import("./../../../src/pages/how-to-download-soundcloud-playlist.tsx" /* webpackChunkName: "component---src-pages-how-to-download-soundcloud-playlist-tsx" */),
  "component---src-pages-how-to-download-soundcloud-tracks-tsx": () => import("./../../../src/pages/how-to-download-soundcloud-tracks.tsx" /* webpackChunkName: "component---src-pages-how-to-download-soundcloud-tracks-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-likes-tsx": () => import("./../../../src/pages/likes.tsx" /* webpackChunkName: "component---src-pages-likes-tsx" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-playlist-tsx": () => import("./../../../src/pages/playlist.tsx" /* webpackChunkName: "component---src-pages-playlist-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-track-tsx": () => import("./../../../src/pages/track.tsx" /* webpackChunkName: "component---src-pages-track-tsx" */)
}

